import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "iOSDC Japan 2018 にメドレーが協賛しました",
  "date": "2018-09-13T08:57:02.000Z",
  "slug": "entry/2018/09/13/175702",
  "tags": ["medley"],
  "hero": "./2018_09_13.png",
  "heroAlt": "iosdc 2018"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部の高井です。`}</p>
    <p>{`メドレーは、去年に引き続き 8/30〜9/2 に早稲田大学で開催された `}<a parentName="p" {...{
        "href": "https://iosdc.jp/2018/"
      }}>{`iOSDC Japan 2018`}</a>{`(以下 iOSDC)に協賛しました。
みなさんご存知かと思いますが、iOSDC は国内の iOS イベントの中では try! Swift と並ぶ最大級のイベントです。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180913/20180913175434.png",
      "alt": "20180913175434.png"
    }}></img>
    <p>{`（オンライン診療アプリ「CLINICS」初期開発時から Swift で実装しています）`}</p>
    <p><a parentName="p" {...{
        "href": "https://itunes.apple.com/jp/app/id1106261604"
      }}>{`CLINICS(クリニクス)　オンライン診療・服薬指導アプリ`}</a></p>
    <p>{`メドレーは今回、シルバースポンサーとして協賛させていただきました。ブース出展はしていないのですが、スポンサー枠で私が参加してきました。`}</p>
    <p>{`オープニングのスポンサー紹介は去年に引き続き今年も三石琴乃さんのナレーションでした！豪華です。`}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/Wy6XCIDJRIU" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
    <h1>{`イベントの様子`}</h1>
    <p>{`ランチのお弁当はもちろん、朝はドーナツ、夕方の LT が始まるとビールが提供されるなど至れり尽くせりでした。セッションは最大 4 つが同時に進み、それに加えてアンカンファレンスと特定のテーマを設けたディスカッション企画などもあったのですが、進行も非常に円滑で非常に参加者の満足度の高いカンファレンスではないかと思います。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180913/20180913175617.png",
      "alt": "20180913175617.png"
    }}></img>
    <h1>{`セッション`}</h1>
    <p>{`今年は Swift のコンパイラのソースから Swift の機能を解説するようなセッションがいくつかありました。去年はそのようなセッションはなかったのではないかと思います。Swift がオープンソース化されて 3 年弱ぐらいになり、日本でも Swift のコミュニティがより成熟しているように感じました。
また、機械学習などのキャッチーなトピックの話題が少し減って、業務上得た知見や設計についてなどの実用的なテーマが多かった印象でした。
特に View の設計や実装についてのセッションが面白かったです。やはり、iOS アプリ開発では View 周りの実装が悩みの多い領域ですよね。`}</p>
    <p>{`ここからは特に気になったセッションをいくつかご紹介します。`}</p>
    <h2>{`MicroViewController で無限にスケールする iOS 開発`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.icloud.com/keynote/0vgTYDXyHQTd0l1FKTiF1jT7g#MicroViewController-en"
      }}>{`https://www.icloud.com/keynote/0vgTYDXyHQTd0l1FKTiF1jT7g#MicroViewController-en`}</a></p>
    <p>{`UI パーツごとに ViewController を持たせて、一つの画面の異なる機能を複数のエンジニアで開発しやすいように実装しているというお話でした。一般的な画面単位で ViewController を持つ実装だと、開発の人数を増やしてもコンフリクトやオーバーヘッドが発生しやすくなり、効率的に開発することが難しくなってしまいます。そのため、開発チームの規模をスケールさせることができないということからそのような方法を取り入れたそうです。`}</p>
    <p>{`MicroViewController を採用したことによって、一つのアプリに 20 人のエンジニアを充てることができるようになったということでしたが、他にそんなところあるのでしょうか、、、という気がしないでもなかったです。ただ、数人でやっていてもコンフリクトはよく起こりますし（project.pbxproj、、、！）、機能を追加したり、削除したりということを素早く試行錯誤するのには良さそうだなと思いました。`}</p>
    <p>{`また、ビルドの効率化のためにサンドボックスアプリを用意したり、ViewController のテンプレートを作って実装の効率化を図るなど開発効率向上の参考になる取り組みも多かったです。`}</p>
    <h2>{`デバイス・ OS バージョンの依存が少なく、メンテナンスしやすいビューを作る`}</h2>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/b3f154dc44134795b521b00673ff5563" title="iOSDC2018.pdf" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "314px"
    }} data-ratio="1.78343949044586"></iframe>
    <p>{`ビューの実装について起こりがちな問題とその対処法について紹介したセッションでした。特にレイアウト崩れを防ぐために意識すべきポイントについて、具体的な失敗例を示しながら紹介されていました。`}</p>
    <p>{`UI コンポーネントのサンプル実装（`}<a parentName="p" {...{
        "href": "https://github.com/folio-sec/Folio-UI-Collection/tree/master/Folio-UI-Collection"
      }}>{`https://github.com/folio-sec/Folio-UI-Collection/tree/master/Folio-UI-Collection`}</a>{`）も紹介されていましたが、CLINICS でも共通 UI をコンポーネント化して使っているので、カスタム View の実装方法やコンポーネントの粒度などが再確認でき、非常に有益でした。また、UI のユニットテストもあり、CLINICS ではあまり UI 関連のテストは書けていないので参考にしたいと思います。`}</p>
    <h2>{`宣言的 UICollectionView`}</h2>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/72eec24556a74fcaa13fa374289acfe3" title="Declarative UICollectionView" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "314px"
    }} data-ratio="1.78343949044586"></iframe>
    <p>{`UICollectionView に複数の種類がある場合（よくあります！）に、宣言的な実装をすることでコードの見通しをよくする方法を提案されているセッションでした。`}</p>
    <p>{`自分もあの switch 文をまとめようとして、うまくいかなかった経験があるのでとても興味深かったです。すぐに自分たちのコードにも適用できる実用的なセッションでした。`}</p>
    <p>{`あとは ReactorKit のライブコーディングや差分検出アルゴリズムに関連する話がいくつかあり、ちょうど業務で ReactorKit と RxDataSources を使っているので参考になりました。`}</p>
    <h2>{`差分アルゴリズムの原理について`}</h2>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/f7b35578419d420088be83eb2638ac5a" title="Difference Algorithm" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "420px"
    }} data-ratio="1.3333333333333333"></iframe>
    <p>{`UITableView などで変更があった箇所だけを更新するのに利用される差分検出のアルゴリズムについて解説したセッションでした。IGListKit や RxDataSources などで使われているアルゴリズムと Android の DiffUtil で利用されているアルゴリズムの原理について紹介されていました。ライブラリを使っていると、利用方法は分かるけど内部でどう動いてるかはあまり理解していないということも割とあると思いますが、その辺りを理解できると用途に応じて最適なライブラリを選択できたり、より効果的な使い方ができそうだと思いました。`}</p>
    <h2>{`5000 行の UITableView を差分更新する`}</h2>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/9b0b1ea657494e6f840886cd039b90af" title="5000 行の UITableView を差分更新する / Difference update UITableView with 5000 rows" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "314px"
    }} data-ratio="1.78343949044586"></iframe>
    <p>{`差分更新のライブラリを使って多数行を更新した際に発生した問題の紹介とその原因を特定して、改善した内容についてのお話でした。採用していたライブラリは Differ でしたが、ライブラリごとの特徴や Instruments によるボトルネックの特定などが参考になりました。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`iOSDC は去年に引き続きの参加だったのですが、今年のセッションもどれも興味深く、勉強になることが多かったです。またセッションの裏で特定の技術についてディスカッションするコーナーができるなど年々充実してきているように感じました。また、LT のときの会場の一体感はとても良いなあと思いました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180913/20180913175405.png",
      "alt": "20180913175405.png"
    }}></img>
    <p>{`弊社のアプリ開発でもそういった知見などを活かして開発していける仲間を引き続き募集しています! 興味がある方は、こちらからご連絡ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley"
      }}>{`https://www.wantedly.com/companies/medley`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      